import { useEffect, useState } from "react";
import { useParams,useNavigate} from "react-router-dom";
import { api_post,getAPIHost } from "../api";

import { useCookies } from "react-cookie";
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box,Button,FormControl, FormErrorMessage, FormHelperText, FormLabel, Heading, Input, VStack } from "@chakra-ui/react";
import { useFormik } from "formik";
import { AppUser } from "../user";

interface UserActivateRequest{
    username : string;
    otp : string;
};

interface UserActivationReponseBody{
    token : string;
}

interface FormValues{
    username : string;
    otp_code : string;
};

export interface ActivateProps{
    username : string;
}

export function Activate(){

    const {username} = useParams();
    const navigate = useNavigate();

    let auth_cookie_name = 'auth_user_' + username;
    const [cookie,setCookie] = useCookies([auth_cookie_name,"username"]);

    const [errorMessage,setErrorMessage] = useState<string>("");
    const [isError,setIsError] = useState<boolean>(false);
    const [activated, setActivated] = useState<boolean>(false);

    useEffect(() => {
        if (!activated){
            if(cookie[auth_cookie_name]){
                const app_user = (cookie[auth_cookie_name] as AppUser);
                if (app_user.username === username){
                    console.log(username, "is already activated so redirecting");
                    navigate("/");
                }
            }        
        }
    },[cookie,username,auth_cookie_name,navigate,activated]);

    const vals : FormValues = {
        username : username ?? "",
        otp_code : ""
    };

    const activateForm = useFormik({
        initialValues : vals,
        onSubmit : (async values => {
            //const url = getAPIHost() + '/auth/activate';
            const url = new URL('/auth/activate',getAPIHost());
            const req : UserActivateRequest = {
                username : values.username,
                otp : values.otp_code
            }
            const resp = await api_post(url,req,false);
            if (resp.ok){
                const un = username ?? values.username;
                auth_cookie_name = "auth_user_" + un;
                
                const resp_data : UserActivationReponseBody = await resp.json();
                console.log("activate request returned ",resp_data);
                const options = {
                    maxAge : 31536000
                }
                setCookie("username",un,options);
                setCookie(auth_cookie_name,{username: un,token : resp_data.token},options);
                setActivated(true);
                setTimeout(() => {
                    navigate("/");
                },3000);
            }else{
                const json = await resp.json();
                setIsError(true);
                setErrorMessage(json.error);
            }
        })
    })

    return(
        <Box p={5}>
        <form onSubmit={activateForm.handleSubmit}>
            <VStack align={'center'}>
                <Heading>Activate {username}</Heading>
                <Box mt={5}>
                <Alert status='success' mt={5} hidden={!activated} variant='solid'>
                        <AlertIcon />
                        <Box>
                            <AlertTitle>Activation Successful</AlertTitle>
                            <AlertDescription>Your account was verified. I will redirect in 3 seconds</AlertDescription>
                        </Box>
                </Alert>
                <FormControl id="username" pt={5} isRequired isReadOnly={activated} hidden={username !== undefined}>
                    <FormLabel htmlFor="username" fontWeight={'bold'}>Username</FormLabel>
                    <Input 
                    type={'text'}
                    value = {activateForm.values.username}
                    onChange = {activateForm.handleChange}
                    />
                    <FormHelperText>The username you were assigned</FormHelperText>
                </FormControl>

                <FormControl id="otp_code" pt={5} isRequired isReadOnly={activated}>
                    <FormLabel htmlFor="otp_code" fontWeight={'bold'}>Activation Code</FormLabel>
                    <Input 
                    type={'text'}
                    value = {activateForm.values.otp_code}
                    onChange = {activateForm.handleChange}
                    />
                    {!isError ? 
                    (
                    <FormHelperText>Enter the code that you were sent via email</FormHelperText>
                    ) : 
                    (
                        <FormErrorMessage>{errorMessage}</FormErrorMessage>
                    )
                }
                </FormControl>
                </Box>
                <VStack p={7}>
                    <Button
                        mt={5}
                        colorScheme='blue'
                        type='submit'
                        disabled={activateForm.isSubmitting || activated}
                        >
                        Activate
                    </Button>
                </VStack>
               
            </VStack>
        </form>
        </Box>

    )
}