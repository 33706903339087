import { Flex, VStack, TableContainer, Table, Thead, Tr, Th, Tbody, Td } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { api_get, getAPIHost } from "../api";
import { Client, getClients, getLoggedInUser } from "../user";

interface Contract{
    client : Client;
    hours_per_week : number;
};

export default function Clients(){

    const [clients,setClients] = useState<Contract[]>([]);
    useEffect(() => {
        async function fetchContracts() {
            const username = getLoggedInUser()?.username;
            const url = new URL("/contract/user/"+username,getAPIHost());
            const resp = await api_get(url);
            const contracts = await resp.json() as Contract[];
            
            if (clients.length !== contracts.length){
                setClients(contracts);
            }
        }
        fetchContracts()
        .catch(console.error);

    },[clients]);

    return (
        <Flex mt={5} ml={5}>
        <VStack align={'center'}>
        <TableContainer pt={5} width='container.md'>
            <Table variant='striped'>
                <Thead>
                    <Tr>
                        <Th>Client</Th>
                        <Th>Contact Name</Th>
                        <Th>Contact Email</Th>
                        <Th>Hours Per Week</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {clients.map((e,idx) => (
                    <Tr key={e.client.name + idx}>
                        <Td>{e.client.name}</Td>
                        <Td>{e.client.contactName}</Td>
                        <Td>{e.client.contactEmail}</Td>
                        <Td>{e.hours_per_week}</Td>
                </Tr>
                    
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
        </VStack>
    </Flex>
)
}