import { Cookies } from "react-cookie";
import { api_get,getAPIHost } from "./api";

const cookies = new Cookies();

export function getLoggedInUser():AppUser | undefined{

    const username = cookies.get("username") as string;
    //console.log("username cookie is ",username);
    
    if (username){
        let user = cookies.get("auth_user_" + username) as AppUser;
        return user;
    }else{
        return undefined;
    }
}

export interface Client{
    id : number;
    name : string;
    contactName? : string;
    contactEmail? : string;
};

export interface AppUser{
    username : string;
    token : string;
}

export interface User{
    id : number;
    name : string;
    email : string;
    clients : Client[];
};

export interface Contract{
    client : Client;
    user : User;
    hoursPerWeek : number;    
}

export async function getClients(){
    const currentUser = getLoggedInUser();
    if (!currentUser){
        return [];
    }
    
    const url = new URL('/user/' + currentUser.username,getAPIHost());
    const response = await api_get(url);
    const data = await response.json();
    const fetchedUser = data as User;

    return fetchedUser.clients;
}
