import { Box, Button, FormControl, FormLabel, Input, Select } from "@chakra-ui/react";
import { Form,Formik, FormikHelpers, Field } from "formik";
import { useEffect, useState } from "react";
import { api_get, getAPIHost } from "../api";

interface Task{
  name : string;
  duration : number;
  orgname : string;
  notes? : string;
  when? : Date;
  username : string;
}

interface Values {
    taskName : string;
    clientName : string;
    duration : number;
  };

  async function getTask(){
    const url = new URL("/task/id/1",getAPIHost());
    const resp = await api_get(url);
    const task = await resp.json() as Task;

    const vals : Values = {
      taskName : task.name,
      clientName : task.orgname,
      duration : task.duration
    }

    return vals;
  }

export function TestForm(){

    const defaultVals : Values = {
      taskName :"Test Task",
      clientName : "ACME",
      duration : 60
    };

    const [vals,setVals] = useState<Values>(defaultVals);

    useEffect(() => {
      if (vals === defaultVals){
        getTask()
        .then(vals => {
          console.log("setting task");
          setVals(vals);          
        })          
      }
    },[vals]);

    return(
        <Formik
        initialValues={vals}
        enableReinitialize={true}

        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 500);
        }}
      >
        <Box p={5}>
        <Form>
            <FormControl pt={5}>
                <FormLabel htmlFor="taskName">Task Name</FormLabel>
                <Field id="taskName" name="taskName"  as={Input}/>
            </FormControl>

        <FormControl pt={5}>
            <FormLabel htmlFor="clientName">Client</FormLabel>
            <Field id="clientName" name="clientName" as={Input}/>
        </FormControl>

        <FormControl pt={5}>
            <FormLabel htmlFor="duration">Duration</FormLabel>
            <Field id="duration" name="duration" as={Input}/>
        </FormControl>


          <Button mt={10} type="submit">Submit</Button>
        </Form>
        </Box>
      </Formik>
    )
}