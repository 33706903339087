import { Box, Text, List, ListItem, Flex } from "@chakra-ui/react";
import { MenuNavLink } from "./NavLink";

export interface SideBarItem{
    name : string;
    section : string;
    url : string;
}

export interface SideBarProps{
    items : SideBarItem[]
}

export function SideBar({items} : SideBarProps){

    // now build out a mapping from item back to section header
    let sectionMap = new Map<string,SideBarItem[]>();

    items.forEach(e => {
        if (!sectionMap.has(e.section)){
            sectionMap.set(e.section,[])
        }

        let sbitems = sectionMap.get(e.section);
        sbitems!.push(e);
    })

    const sectionHeaders = sectionMap.keys();

    return(
        <Box 
            mt={5}>
            {[...sectionHeaders].map((e,idx) => (
                <List>
                    <Flex h="10" alignItems="center" mx="6" mt="3" justifyContent="space-between" key={"flex+" + e + idx}>
                        <Text fontSize="xl" key={"text" + e + idx}>
                            {e}
                        </Text>
                    </Flex>
                    {sectionMap.get(e)?.map((item,idx) => (
                        <ListItem key={"li" + item.url + idx}>
                            <MenuNavLink key={"mnl" + item.url + idx} url={item.url}>
                                {item.name}
                            </MenuNavLink>
                        </ListItem>
                    ))}
                </List>
            ))}
        </Box>
    )
}