import { Link as RouterLink } from 'react-router-dom';
import { Flex, FlexProps, Link } from "@chakra-ui/react";

export interface NavLinkProps extends FlexProps {
    url : string;
};

export const NavLink = ({ url,children, ...rest }: NavLinkProps) => {
    return (
      <Link 
        to={url} 
        as={RouterLink} 
        style={{ textDecoration: 'none' }} 
        _focus={{ boxShadow: 'none' }}
        key={";ink"+url}
        >
        <Flex
          align="center"
          px="4"
          py="2"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          {...rest}>
          {children}
        </Flex>
      </Link>
    );
  };

  export const MenuNavLink = ({ url,children, ...rest }: NavLinkProps) => {
    return (
        <NavLink
        url = {url}
        _hover={{
            bg: 'cyan.400',
            color: 'white',
          }}
          {...rest}
          >{children}</NavLink>
    )
  }