import { Flex, TableContainer, Table, Thead,Tbody,Tr,Td, Th, Heading, VStack, Box, Text, Select, HStack, Button, Progress, Stat, StatLabel, StatNumber, Link } from "@chakra-ui/react";
import {Link as RouterLink} from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Client, getLoggedInUser} from "../user";
import { CSVLink } from 'react-csv';
import { api_get,getAPIHost } from "../api";

interface Task{
    id : number;
    name : string;
    duration : number;
    when : Date;
    org : Client;
    notes? : string;    
}

interface ReportEntry{
    name : string;
    duration : number;
    when : string;
    client : string;
    notes? : string;
};

interface TotalTime{
    hours : number;
    minutes : number;
}

type ReportType = "all" | "today"| "currentweek" | "lastweek" | "currentmonth" | "month";

function getTotalTimeLogged(tasks:Task[]){
    let totalDurationInMins = 0;
    tasks.forEach(e => {
        totalDurationInMins += e.duration;
    })

    let total = totalDurationInMins/60;
    let totalHours = Math.floor(total);
    let totalMinutes = (total - totalHours) * 60;
    let result : TotalTime = {
        hours : totalHours,
        minutes : totalMinutes
    };

    return result;
};


interface ReportRequestValues{
    month? : Number;
    year? : Number;
};

interface Contract{
    client : Client;
    hours_per_week : number;
};

interface ClientInfo{
    name :string;
    hoursPerWeek : number;
};

function buildURL(report : ReportType, values? : ReportRequestValues){
    let path = '/task/' + getLoggedInUser()?.username;
    switch(report){
        case "currentweek":
            path += '/thisweek';
            break;
        case "lastweek":
            path += '/lastweek';
            break;
        case "currentmonth":
            const month = new Date().getMonth() + 1;
            path += `/month/${month}`;
            break;
                
    }
    return new URL(path,getAPIHost());
};

function Report(){

    let [searchParams] = useSearchParams();
    const [dirty,setDirty] = useState<boolean>(false);
    const [clients,setClients] = useState<ClientInfo[]>([]);
    const [selectedClient,setSelectedClient] = useState<string>("-1");
    const [weeks,setWeeks] = useState<number>(-1);

    const reportType = searchParams.get('type') as ReportType;
    const [tasks,setTasks] = useState<Task[]>([]);

    const url = useRef<string>("");
    const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

    const totalTimeWorked = useMemo<TotalTime>( () => getTotalTimeLogged(tasks),[tasks]);
    const budgetUsed = useMemo<number>(() => getBudgetUsed(),[tasks]);

    function getBudgetUsed(){
        const totalTime = totalTimeWorked.hours + (totalTimeWorked.minutes/60);
        let totalBudget = 0;
        const clientIdx = Number.parseInt(selectedClient);
        if (clientIdx === -1){
            clients.map(e => {
                totalBudget += e.hoursPerWeek;
                return totalBudget;
            });
    
        }
        else{
            totalBudget = clients[clientIdx].hoursPerWeek;
        }
        totalBudget *= weeks;
    
        const ratio =  totalTime/totalBudget;
        return ratio * 100;
    };

    useEffect(()=>{

        if (url.current !== window.location.href){
            setSelectedClient("-1");
            url.current = window.location.href;
            setDirty(true);
        }

        async function getContracts(){
            const url = new URL("/contract/user/"+getLoggedInUser()?.username,getAPIHost());
            const response = await api_get(url);
            let contractRecords = await response.json() as Contract[];

            return contractRecords;
        }

        async function fetchTasks(){        
            const url = buildURL(reportType);
            
            switch(reportType){
                case 'all':
                default:
                    setWeeks(-1);
                    break;
                case 'currentmonth':
                case 'month':
                    setWeeks(4.33);
                    break;
                case 'currentweek':
                case 'lastweek':
                    setWeeks(1);
            }
        
            const response = await api_get(url);
            const fetchedContracts = await getContracts();

            let clientInfos = fetchedContracts.map(e => {return {name : e.client.name, hoursPerWeek : e.hours_per_week} as ClientInfo}); 
            console.log(clientInfos);
            const fetchedTasks = await response.json() as Task[];

            if (selectedClient === "-1"){
                setTasks(fetchedTasks);
            }else{
                const idx = Number.parseInt(selectedClient);
                const filterClient = clients[idx];

                let tasks = fetchedTasks.filter(t => t.org.name === filterClient.name);
                setTasks(tasks);
            }
            setClients([...clientInfos]);        
            setDirty(false);    
        }
        if (dirty){
            fetchTasks()
            .catch(console.error);
        }

    },[selectedClient,reportType,clients,dirty]);

    function generateCSVReport(){
        csvLinkRef.current?.link.click();
    }

    function getCSVReportName(){

        let stub = ""
        let reportName = "rash-report-" + getLoggedInUser() + "-" + stub + ".csv";

    }

    function getCSVReportData(){
        let data : ReportEntry[]= [];
        tasks.forEach(task => {
            const entry = {
                client : task.org.name,
                name : task.name,
                when : new Date(task.when).toLocaleString(),
                duration : task.duration,
                notes : task.notes ?? ""
            }
            data.push(entry);
        })

        return data;
    }
    
    const onClientSelect = (e: React.FormEvent<HTMLSelectElement>) => {
        setSelectedClient(e.currentTarget.value);
        setDirty(true);
    }

    return (
        <Flex mt={5} ml={5}>
            <VStack align={'center'}>
            <ReportTitle report={reportType}/>
            <Box pt={5}>
                <HStack>
                <Text>Client</Text>
                <Select value={selectedClient} onChange={onClientSelect}>
                    {clients.map((e,idx) => (
                        <option key={idx} value={idx.toString()}>{e.name}</option>
                    ))}
                    <option key='-1' value='-1'>All clients</option>
                </Select>
                </HStack>

            </Box>
            <TableContainer pt={5} width='container.md'>
                <Table variant='striped'>
                    <Thead>
                        <Tr>
                            <Th>Client</Th>
                            <Th>Date</Th>
                            <Th>Time</Th>
                            <Th>Length</Th>
                            <Th>Name</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {tasks.map((e,idx) => (
                            <Tr key={e.name + e.org + e.when + idx}>
                                <Td>{e.org.name}</Td>
                                <Td>{(new Date(e.when)).toLocaleDateString()}</Td>
                                <Td>{(new Date(e.when)).toLocaleTimeString()}</Td>
                                <Td>
                                    <SmartDuration duration={e.duration}/>
                                    </Td>
                                <Td>{e.name}</Td>
                                <Td>
                                    <Link as={RouterLink} color="green" to={"/log/"+e.id}>Edit</Link>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <Box pt={5} color={'teal'}>
                <VStack>
                    <HStack>
                        <Text>Total hours worked : </Text>
                        <Text>{totalTimeWorked.hours.toString()} hours</Text>
                    </HStack>
                    {totalTimeWorked.minutes > 0 && <Text> and {totalTimeWorked.minutes.toString()} minutes</Text>}
                    {weeks > 0 && 
                    <Stat>
                    <StatLabel>Budget Used</StatLabel>
                    <StatNumber>{budgetUsed.toFixed(0)} %</StatNumber>
                    <Progress value={budgetUsed} />
                  </Stat>
                    }
                </VStack>
            </Box>
            <Box mt={10} pt={10}>
                <Button colorScheme={'blue'} onClick={generateCSVReport}>Download Report</Button>
                <CSVLink
                    data={getCSVReportData()}
                    filename="report.csv"
                    className="hidden"
                    ref={csvLinkRef}
                    target='_blank'
                />

            </Box>
            </VStack>
        </Flex>
    )
}

interface SmartDurationProps{
    duration : number;
}

function SmartDuration({duration}:SmartDurationProps){
    let text = ""

    const durationInHours = duration/60;
    const hours = Math.floor(durationInHours);
    const minutes = (durationInHours - hours) * 60;

    if (hours > 0){
        text += hours;
        hours > 1 ? text += " hours" : text += " hour";
    }

    if (minutes > 0){
        text += `${minutes} minutes`
    }

    return (
        <>
            {text}
        </>
    );
}

interface ReportTitleProps{
    report : ReportType;
}

function ReportTitle({report}:ReportTitleProps){

    let text = "";

    switch(report){
        case "currentweek":
            text = "Worked logged this week";
            break; 
        case "lastweek":
            text = "Work logged last week";
            break; 
        case "currentmonth":
            text = "Work logged this month";
            break; 
        case "all":
        default:
            text = "All work logged";
    
    }

    return(
        <Flex alignItems="center">
            <Heading>{text}</Heading>
        </Flex>
    )
}

export default Report;