import { Grid, GridItem } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { getLoggedInUser } from '../user';
import { getAPIStatus } from '../api';
import Footer from './Footer';
import Header from './Header';
import { SideBarItem, SideBar } from './SideBar';

const sideBarItems : SideBarItem[]= [
    {
      name : "Log Time",
      url : "/log",
      section : "Logging",
    },
    {
      name : "This Week",
      url : "/report?type=currentweek",
      section : "Reports"
    },
    {
        name : "Last Week",
        url : "/report?type=lastweek",
        section : "Reports"
      },
      {
        name : "This Month",
        url : "/report?type=currentmonth",
        section : "Reports"
      },
      {
        name : "Show All",
        url : "/report?type=all",
        section : "Reports"
      },
      {
        name : "Show All",
        url : "/clients",
        section : "Clients"
      },

    ];

function MainPage(){

    const navigate = useNavigate();
    let app_user = getLoggedInUser();

    useEffect(() => {
      getAPIStatus()
      .then(result => {
        console.log(result);
      });

      if (!app_user){
        navigate("/activate");
      }
    },[app_user,navigate]);

    return(
        <Grid
            templateAreas={`"header header"
                            "nav main"
                            "nav footer"`}
            gridTemplateRows={'50px 1fr 30px'}
            gridTemplateColumns={'250px 1fr'}
            h='container.lg'
            gap='1'
            fontWeight='bold'
        >
        <GridItem pl='2' area={'header'}>
        <Header/>
        </GridItem>
        <GridItem pl='2' area={'nav'}>
            <SideBar items={sideBarItems}/>
        </GridItem>
        <GridItem pl='2' area={'main'}>
            <Outlet/>
        </GridItem>
        <GridItem pl='2' area={'footer'}>
            <Footer/>
        </GridItem>
</Grid>
    );
}

export default MainPage;