import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, FormControl, FormLabel, Heading,  Input,  Select, Textarea, VStack } from "@chakra-ui/react";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api_get, api_post,api_put,getAPIHost } from "../api";
import { Client, getLoggedInUser, getClients} from "../user";

interface Duration{
    displayName : string;
    value : number;
}
interface FormValues{

    clientChoice : string;
    durationChoice : string;
    taskName : string;
    notes? : string;
    when : string;
};

interface Task{
    name : string;
    duration : number;
    orgname : string;
    notes? : string;
    when? : Date;
    username : string;
}
let durationData : Duration[] = [
    {
        displayName : "Fifteen Minutes",
        value : 15
    },
    {
        displayName : "Thirty Minutes",
        value : 30
    },
    {
        displayName : "One Hour",
        value : 60
    },
    {
        displayName : "Two Hours",
        value : 120
    },
    {
        displayName : "Three Hours",
        value : 180
    },
    {
        displayName : "Four Hours",
        value : 240
    },
    {
        displayName : "Six Hours",
        value : 360
    }

];

function LogTime(){

    const {taskId} = useParams();
    const [task,setTask] = useState<Task|null>(null);
    const [clients,setClients] = useState<Client[]>([]);
    const [submitted,setSubmitted] = useState<boolean>(false);
    const [loading,setLoading] = useState<boolean>(true);
    const [canLogTasks,setCanLogTasks] = useState<boolean>(false);
    const app_user = getLoggedInUser();
    
    useEffect(() => {
        async function fetchTask() {
            const url = new URL('/task/id/' + taskId,getAPIHost());
            const resp = await api_get(url);
            const task = await resp.json() as Task;
            setTask(task);
        }

        async function fetchClients() {
            const fetchedClients = await getClients();
            if (clients.length !== fetchedClients.length){
                setClients(fetchedClients);
                setCanLogTasks(true);    
            }
        }
        setLoading(true);
        fetchClients()
        .catch(console.error);

        if (taskId){
            fetchTask();
        }
        setLoading(false);

    },[clients,taskId]);

    
    let vals : FormValues = {
        clientChoice : '0',
        durationChoice : '2',
        taskName : '',
        notes : '',
        when : new Date().toISOString().slice(0,19)
    };

    if (task){
        vals.taskName = task.name;
        vals.notes = task.notes;

        const durationIdx = durationData.findIndex(e => e.value === task.duration);
        vals.durationChoice = durationIdx > -1 ? durationIdx.toString() : '2';

        const clientIdx = clients.findIndex(e => e.name === task.orgname);
        vals.clientChoice = clientIdx > -1 ? clientIdx.toString() : '0';

        vals.when = new Date(vals.when).toISOString().slice(0,19);
        vals.notes = task.notes;
    }

    async function SubmitValues(values:FormValues){
        const client = clients[Number.parseInt(values.clientChoice)];
        const duration = durationData[Number.parseInt(values.durationChoice)];
        let url : URL;
        console.dir("task is ",task);
        if (!taskId){
            url = new URL('/task/new',getAPIHost());
        }
        else{
            url = new URL('/task/'+ taskId,getAPIHost());
        }
        const req : Task = {
            name : values.taskName,
            orgname : client.name,
            duration : duration.value,
            username : app_user ? app_user.username : "",
            notes : values.notes,
            when : new Date(values.when)
        }
        const resp = task? await api_put(url,req) : await api_post(url,req);
        setSubmitted(resp.ok);
        setTimeout(() => setSubmitted(false),2000);

    }

    return (
        <Box p={5}>
        <Formik
        initialValues={vals}
        enableReinitialize={true}
        onSubmit={(
            values: FormValues,
            { setSubmitting }: FormikHelpers<FormValues>
              ) => 
              {
                SubmitValues(vals)
                .catch(console.error);
                }}
            >
            
            <VStack align={'center'}>
                <Heading>
                    {task ? ("Edit Task") : ("Log Task")}
                </Heading>
                <Box mt={5}>
                <Alert status='error' mt={5} hidden={canLogTasks || loading} variant='solid'>
                        <AlertIcon />
                        <Box>
                            <AlertTitle>Time Logging Disabled</AlertTitle>
                            <AlertDescription>No clients set</AlertDescription>
                        </Box>
                    </Alert>
                <Form>

                <FormControl id="taskName" pt={5} isRequired isReadOnly={!canLogTasks}>
                    <FormLabel htmlFor="taskName" fontWeight={'bold'}>Task Name</FormLabel>
                    <Field
                    as={Input}
                    placeholder = 'e.g. Wrote some emails'
                    id="taskName"
                    name="taskName"
                    />
                </FormControl>

                <FormControl id="when" pt={5} isReadOnly={!canLogTasks}>
                    <FormLabel htmlFor="when" fontWeight={'bold'}>When</FormLabel>
                    <Field
                        as={Input}
                        size="md"
                        type="datetime-local"
                        value={new Date().toISOString().slice(0,19)}
                        />
                </FormControl>
                <FormControl id="clientChoice" pt={5} isRequired isReadOnly={!canLogTasks}>
                    <FormLabel htmlFor="clientChoice" fontWeight={'bold'}>Client</FormLabel>
                    <Field id="clientChoice" name="clientChoice" as={Select}>
                        {clients.map((c,idx) => (
                            <option key={c.id} value={idx.toString()}>{c.name}</option>
                            ))}
                    </Field>
                </FormControl>

                <FormControl id="durationChoice" pt={5} isRequired isReadOnly={!canLogTasks}>
                    <FormLabel htmlFor="durationChoice" fontWeight={'bold'}>Duration</FormLabel>
                    <Field id="durationChoice" name="durationChoice" as={Select}>
                        {durationData.map((d,idx) => (
                            <option key={d.value} value={idx.toString()}>{d.displayName}</option>
                            ))}
                    </Field>
                </FormControl>

                <FormControl id="notes" pt={5} isReadOnly={!canLogTasks}>
                    <FormLabel htmlFor="notes" fontWeight={'bold'}>Notes</FormLabel>
                    <Field id="notes" name="notes" as={Textarea} placeholder="notes in here"/>
                </FormControl>

                <Button
                    mt={10}
                    colorScheme='blue'
                    type='submit'
                    disabled={!canLogTasks || loading}
                    > Submit
                    </Button>
                    <Box mt={5} hidden={!submitted} color={'green.300'}>
                        <Heading size={"sm"}>Task successfully logged</Heading>
                    </Box>
                </Form>

                </Box>
                </VStack>
               
        </Formik>
        </Box>
    )
}

export default LogTime;