import { Routes,Route } from "react-router-dom";
import LogTime from "./routes/Log";
import MainPage from "./Components/MainPage";
import Report from "./routes/Report";
import { Activate } from "./routes/Activate";
import Clients from "./routes/Clients";
import { TestForm } from "./routes/TestForm";

function App() {


  return (
    <Routes>
      <Route path="/" element={<MainPage/>}>
      <Route 
        path = "/activate/"
        element = {<Activate/>}
        />
        <Route 
        path = "/activate/:username"
        element = {<Activate/>}
        />
        <Route
        path="/log"
        element={<LogTime/>}
        />
        <Route
        path="/log/:taskId"
        element={<LogTime/>}
        />
        <Route
        path="/report"
        element={<Report/>}
        />
        <Route
        path="/clients"
        element={<Clients/>}
        />
        <Route
        path="/test"
        element={<TestForm/>}
        />

      </Route>
    </Routes>
  );
}

export default App;
