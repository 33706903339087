import { Box, Flex, Link, Text, Spacer } from "@chakra-ui/react";
function Footer(){

    return(
        <Flex minWidth='max-content' alignItems='center' pt={10} fontFamily={'monospace'}>
            <Box p='2'>
                <Link
                    fontSize={'xs'}
                    href="https://www.dynamiccast.net"
                    isExternal={true}
                    >
                    Made by DynamicCast
                </Link>
            </Box>
            <Spacer />
            <Box p='2'>
                <Text fontSize={'xs'}>(c) DynamicCast 2022</Text>
            </Box>
      </Flex>

    )
}

export default Footer;