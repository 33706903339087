import { getLoggedInUser } from "./user";
import config from './conf.json';

type RequestMethod = "GET" | "POST" | "PUT" | "DELETE";

const loggedInUser = getLoggedInUser();
export function getAPIHost(){

    const env = process.env.NODE_ENV;
    let baseURL = "";

    switch(env){
        case "development":
            baseURL = config.api_hostname.dev;
            break;
        case "production":
        case "test":
        default:
            baseURL = config.api_hostname.prod;
    }
    return baseURL;
}

export async function getAPIStatus(){
    const url = new URL("/status",getAPIHost());

    const resp = await api_get(url,false);
    const data = await resp.json();

    if (data){
        return "Running";
    }
    else{
        return "Not running";
    }
}

function getHeaders(with_auth:boolean){
    let headers : HeadersInit= {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      };

    if(with_auth && loggedInUser){
        headers['X-Auth-Token'] =  loggedInUser?.token;
    }
    return headers;
}

async function makeRequest(url:URL,method:RequestMethod,with_auth:boolean){
    const headers = getHeaders(with_auth);

    const response = await fetch(url,{
        headers : headers,
        method : method,
    });

    return response;
}

export async function api_get(url:URL, with_auth:boolean=true){
    //console.log("api_get ",url.toString());

    return makeRequest(url,"GET",with_auth);
}

export async function api_post(url:URL,body:Object,with_auth:boolean=true){

    console.log("api_post ",url.toString());
    return makeRequest(url,"POST",with_auth);
}

export async function api_put(url:URL,body:Object,with_auth:boolean=true){

    console.log("api_put",url.toString());
    return makeRequest(url,"PUT",with_auth);
}

export async function api_delete(url:URL,body:Object,with_auth:boolean=true){
    console.log("api_delete",url.toString());
    return makeRequest(url,"DELETE",with_auth);
}