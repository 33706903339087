import { Link as RouterLink } from 'react-router-dom';
import { Flex, Heading, Link, Text, Box,Spacer } from "@chakra-ui/react";
import { getLoggedInUser } from '../user';

function Header(){
    const user = getLoggedInUser();
    const username = user ? user.username : ""
    return(
        <Flex minWidth='max-content' alignItems='center' gap='2' border={"1px"}>
        <Box p='2'>
        <Heading>
            <Link
                fontSize={'3xl'}
                to="/"
                as={RouterLink}
                >
                RASHOMON
            </Link>
        </Heading>
        </Box>
        <Spacer />
        <Box p='2'>
          <Text>{username}</Text>
        </Box>
      </Flex>
    )
}

export default Header;